import React, { useCallback, useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { Button } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import Field from "../../components/common/form/formik/Field";
import PageTitle from "../../components/common/items/page-title";
import Loading from "../_layouts/Loading";
import { TypesOfInput } from "../../models/fields/IField";
import { createTestLead, getTestLeadFields } from "../../api/leads-api";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { fetchIntegrations } from "../../store/thunks/integrations";
import { fetchList } from "../../store/thunks/teams";
import { ILog } from "../../models/ILog";
import { JSONTree } from "react-json-tree";
import { ILead, TestLeadInitialValues } from "../../models/ILead";
import { FetchOptionsParams, ObjectWithKeys } from "../../types/global";
import { fetchOneLead } from "../../store/thunks/leads";
import * as Yup from 'yup';
import { PayloadAction } from "@reduxjs/toolkit";
import { ModelRes } from "../../api/utils";

type TestLeadDataT = ObjectWithKeys<string>;

const CreateTestLead = () => {
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const { currentLead } = useAppSelector((state) => state.leadsReducer);
  const { id: leadId } = useParams();
  const [fieldNames, setFieldNames] = useState<string[]>([]);
  const [initialValues, setInitialValues] = useState<TestLeadInitialValues | null>(null);
  const { integrations } = useAppSelector((state) => state.integrationsReducer);
  const { list } = useAppSelector((state) => state.teamsSlice);
  const [log, setLog] = useState<ILog | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSubmitForm, setIsLoadingSubmitForm] = useState(false);

  const handleRefetchTestLeadData = () => {
    setIsLoading(true);
    return getTestLeadFields().finally(() => setIsLoading(false));
  };

  useEffect(() => {
    handleRefetchTestLeadData().then((testLeadData) => {
      if (!leadId) fillFormData(initialValues, testLeadData.model, true);
      else {
        dispatch(fetchOneLead(leadId)).then((data) => {
          const { payload } = data as PayloadAction<ModelRes<ILead>>;
          fillFormData(initialValues, testLeadData.model, true, payload.model)
        });
      }
    })
  }, [leadId]);

  const increasePhoneNumber = (phoneNumber: string) => {
    let numericPart = parseInt(phoneNumber.replace(/\D/g, ''));
    numericPart++;
    return phoneNumber[0] + numericPart.toString();
  }

  const increaseIp = (ip: string) => {
    const ipNumbers = ip.split('.');
    const numericPart = parseInt(ipNumbers[ipNumbers.length - 1]) + 1;
    ipNumbers.pop();
    return ipNumbers.join('.') + `.${numericPart}`;
  }

  const fillFormDataFromLead = (values: TestLeadInitialValues | null, testLeadData: TestLeadDataT, currentLead: ILead) => {
    if (!currentLead) return null;

    const formFields = Object.keys(testLeadData);

    const { phoneCode, phone, phoneNumber, landing, country, language, ip, team, email } = currentLead;
    const tempObj = {
      ...values,
      ...testLeadData,
      ...currentLead,
      integration: values?.integration,
      isTest: true,
      email: testLeadData.email,
      phoneCode,
      phone: phone
        ? phone?.slice(0, -3) + "111"
        : undefined,
      phoneNumber: phoneNumber
        ? phoneNumber?.slice(0, -3) + "111"
        : undefined,
      landing,
      country,
      language,
      ip,
      team,
    } as TestLeadInitialValues;

    const testLeadInitialValues = formFields.reduce(
      (prev, curr) => ({ ...prev, [curr]: tempObj[curr as keyof TestLeadInitialValues] }),
      {}
    ) as TestLeadInitialValues;

    return {
      ...testLeadInitialValues,
      isTest: true,
    }
  }


  const fillFormDataWithFalseResponseStatus = (values: TestLeadInitialValues | null, testLeadData: TestLeadDataT) => {
    const phone = values?.phone
      ? increasePhoneNumber(values.phone)
      : undefined;
    return {
      ...values,
      isTest: true,
      ip: increaseIp(values?.ip ?? ''),
      email: testLeadData.email,
      phone,
      integration: values?.integration,
      phoneNumber: phone,
    } as TestLeadInitialValues;
  }

  const fillFormDataWithTrueResponseStatus = (values: TestLeadInitialValues | null, testLeadData: TestLeadDataT) => {
    return {
      ...testLeadData,
      integration: values?.integration,
      team: values?.team,
      isTest: true,
    } as TestLeadInitialValues;
  }

  const fillFormData = useCallback(
    (values: TestLeadInitialValues | null, testLeadData: TestLeadDataT, responseStatus: boolean, currLeadData?: ILead) => {
      // console.log({ values, testLeadData, responseStatus, currLeadData });

      const names: string[] = Object.keys(testLeadData);
      setFieldNames(names);

      let data = null;

      // console.log('leadId', leadId, values, testLeadData);

      if (currLeadData) {
        if (!values) {
          data = fillFormDataFromLead(values, testLeadData, currLeadData);
        } else if (!responseStatus) {
          data = fillFormDataWithFalseResponseStatus(values, testLeadData)
        } else {
          data = fillFormDataWithTrueResponseStatus(values, testLeadData)
        }
      } else if (!responseStatus) {
        data = fillFormDataWithFalseResponseStatus(values, testLeadData)
      } else {
        data = fillFormDataWithTrueResponseStatus(values, testLeadData)
      }
      // console.log('data', data);

      setInitialValues(data);
    },
    [fillFormDataFromLead],
  )

  const handleSubmit = (values: TestLeadInitialValues) => {
    setIsLoadingSubmitForm(true)
    createTestLead(values).then((response) => {
      if (response.status) {
        toast.success("Test Lead successfully created");
      } else {
        toast.warning("Failed to create lead, try again");
        handleRefetchTestLeadData().then(data => fillFormData(values, data.model, false))
      }
      setLog(response.log);
    }).catch(() => { }).finally(() => setIsLoadingSubmitForm(false));
  };

  const handleReFetchFields = (values: TestLeadInitialValues) => {
    handleRefetchTestLeadData().then(data => fillFormData(values, data.model, false))
  };

  // console.log('initialValues || (leadId && currentLead.loading) || isLoading', initialValues, leadId, currentLead.loading, isLoading);

  if (!initialValues || (leadId && currentLead.loading) || isLoading) {
    return <Loading />;
  }

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <Link to={`/leads${search}`} className="w-auto">
          <Button className="w-auto mb-3" variant="primary">
            Back
          </Button>
        </Link>
        <PageTitle title="Creating test lead" />
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              {initialValues && (
                <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit} validationSchema={Yup.object({
                  integration: Yup.object().typeError('required').required('required'),
                  // name: Yup.string().required('required')
                })}>
                  {({ values }) => (
                    <Form className="w-100 px-4">
                      <div className="row">
                        {fieldNames.map((field) => (
                          <Field key={field} name={field} label={field} type={TypesOfInput.TEXT} />
                        ))}
                        <Field
                          name="integration"
                          label="Integration"
                          type={TypesOfInput.SELECT}
                          options={integrations.data}
                          async={{
                            fetchCallback: (params: FetchOptionsParams) =>
                              dispatch(
                                fetchIntegrations({
                                  searchTerm: params.searchTerm,
                                  filter: { type: "main" },
                                }),
                              ),
                            isLoading: integrations.loading,
                          }}
                          labelName="name"
                          valueName="id"
                        />
                        <Field
                          name="team"
                          type={TypesOfInput.SELECT}
                          valueName="id"
                          labelName="name"
                          options={list.data}
                          async={{
                            fetchCallback: (params: FetchOptionsParams) =>
                              dispatch(fetchList(params)),
                            isLoading: list.loading,
                          }}
                        />
                        <Field name="phoneCode" type={TypesOfInput.TEXT} label="Phone code" />
                        <Field name="phoneNumber" type={TypesOfInput.TEXT} label="Phone number" />
                        <Field name="isTest" type={TypesOfInput.CHECKBOX} label="Is test" />
                      </div>
                      <div className="d-flex">
                        {isLoading ? (
                          <Loading />
                        ) : (
                          <>
                            <Button
                              type="button"
                              className="form-control w-25 m-auto d-block"
                              variant="outline-primary"
                              onClick={() => handleReFetchFields(values)}
                              disabled={isLoading || isLoadingSubmitForm}
                            >
                              Fetch lead data
                            </Button>
                            <Button
                              type="submit"
                              className="form-control w-25 m-auto d-block"
                              variant="primary"
                              disabled={isLoadingSubmitForm}
                            >
                              Send
                            </Button>
                          </>
                        )}
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </div>
        </section>
        {log && (
          <section className="mb-5">
            <div className="card">
              <div className="card-body">
                <JSONTree
                  data={log}
                  theme={{ base00: "#fff" }}
                  invertTheme={false}
                  hideRoot={true}
                />
              </div>
            </div>
          </section>
        )}
      </div>
    </div>
  );
};

export default CreateTestLead;
