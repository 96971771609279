import { TypeOptions } from "../components/common/items/ColorLabel";
import { GenericOption, OptionModel } from "./fields/ISelect";
import { LabelColors } from "../types/global";

export type SystemsList =
  | "callback"
  | "deposit"
  | "double"
  | "in_work"
  | "long_term_callback"
  | "mislead"
  | "new"
  | "no_answer"
  | "no_money"
  | "not_interest"
  | "not_registered"
  | "not_valid"
  | "test"
  | "under_age_18"
  | "unreachable"
  | "wrong_geo"
  | "wrong_language"
  | "wrong_number"
  | "wrong_person";

export type TypesList =
  | "valid"
  | "replace";

export type IStatus = IStatusChange & {
  id: string;
  isSelected?: boolean;
  system: SystemsList;
  type: TypesList | null;
  createdAt: string;
};

export type IStatusChange = {
  status: "";
  system: OptionModel<GenericOption<SystemsList>>;
  type: OptionModel<GenericOption<TypesList>>;
};

export type IStatusFilter = IStatusChange;

export type IStatusRequest = {
  status: string;
  system: string;
  type: string | null;
};

export const statusOptions: TypeOptions<SystemsList>[] = [
  {
    label: "Callback",
    value: "callback",
  },
  {
    label: "Deposit",
    value: "deposit",
    color: LabelColors.SUCCESS
  },
  {
    label: "Double",
    value: "double",
  },
  {
    label: "In work",
    value: "in_work",
  },
  {
    label: "L. Term Callback",
    value: "long_term_callback",
  },
  {
    label: "Mislead",
    value: "mislead",
  },
  {
    label: "New",
    value: "new",
  },
  {
    label: "No answer",
    value: "no_answer",
  },
  {
    label: "No money",
    value: "no_money",
  },
  {
    label: "Not interest",
    value: "not_interest",
  },
  {
    label: "Not registered",
    value: "not_registered",
  },
  {
    label: "Not valid",
    value: "not_valid",
    color: LabelColors.FAILED
  },
  {
    label: "Test",
    value: "test",
  },
  {
    label: "Underage age 18",
    value: "under_age_18",
  },
  {
    label: "Unreachable",
    value: "unreachable",
  },
  {
    label: "Wrong geo",
    value: "wrong_geo",
  },
  {
    label: "Wrong language",
    value: "wrong_language",
  },
  {
    label: "Wrong number",
    value: "wrong_number",
  },
  {
    label: "Wrong person",
    value: "wrong_person",
  },
];

export const typeOptions: TypeOptions<TypesList>[] = [
  {
    label: "Valid",
    value: "valid",
    color: LabelColors.SUCCESS
  },
  {
    label: "Replace",
    value: "replace",
    color: LabelColors.FAILED
  },
];

export const emptyFilters: IStatusChange = {
  status: "",
  system: null,
  type: null,
};

export const emptyValues: IStatusChange = {
  status: "",
  system: null,
  type: null,
};
