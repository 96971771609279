import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { getLeadClientStatuses, getLeadProcessingHistories, getLeadStatusesHistories } from "../../api/leads-api";
import { superRolesWithPM } from "../../models/IAuth";
import { typeOptions } from "../../models/IClients";
import { ILead, StatusHistory, statusOptions } from "../../models/ILead";
import { UserRole } from "../../models/IUsers";
import { LabelColors } from "../../types/global";
import { formatDateFullTime, getColumnNumberIndex, handleCopy } from "../../utils/formats";
import CheckboxField from "../common/form/CheckboxField";
import ColorLabel from "../common/items/ColorLabel";
import Label from "../common/items/Label";
import { ColumnDef, PaginationType } from "../common/tables/types";

type IColumn = ColumnDef<ILead>;

interface Options {
  pagination: PaginationType;
  role: UserRole;
  handleChangeIsValid: (isValid: boolean, id: string) => void;
  handleChangeIsReplace: (isReplace: boolean, id: string) => void;
}

const joinProcessingHistories = (statusHistories: StatusHistory[]) => {
  if (!statusHistories) return "";
  let result = "";
  const sortedHistories = [...statusHistories].sort((statusA, statusB) => {
    if (!statusA || !statusB) return 0;
    return statusB.updatedAt - statusA.updatedAt;
  });

  sortedHistories.forEach((history) => {
    const updatedAtDate = new Date(history.updatedAt / 1000 / 1000);
    result += `<div>${history.status} - ${
      history.client ? history.client.name : "No"
    } (${formatDateFullTime(updatedAtDate)})<div/>`;
  });
  return result;
};

export default function getColumnDefs(options: Options): IColumn[] {
  const { pagination, role, handleChangeIsValid, handleChangeIsReplace } = options;

  const numberIndex: IColumn = {
    Cell: ({ row: { index }, cell }) => {
      return (
        <div onClick={() => handleCopy(cell.value)} role="button" className="p-1 btn-link">
          {getColumnNumberIndex(pagination, index)}
        </div>
      );
    },
    Header: "№",
    disableFilters: true,
    disableSortBy: true,
    accessor: "id",
    width: "25px",
    id: "№",
  };

  const name: IColumn = {
    Cell: (cell) => {
      const { search } = useLocation();
      if (role === 'affiliate' || role === 'team_lead') {
        return (
          <Link className="font-semibold" to={`/leads/${cell.row.original.id}/logs`}>
            <div>
              {cell.value} {cell.row.original.surname}
            </div>
            {cell.row.original.isTest ? (
              <ColorLabel color={LabelColors.PRIMARY} label="test" isSingleColor />
            ) : null}
          </Link>
        )
      }
      return superRolesWithPM.includes(role) ? (
        <Link className="font-semibold" to={`/leads/${cell.row.original.id}${search}`}>
          <div>
            {cell.value} {cell.row.original.surname}
          </div>
          {cell.row.original.isTest ? (
            <ColorLabel color={LabelColors.PRIMARY} label="test" isSingleColor />
          ) : null}
        </Link>
      ) : (
        <>
          <div>
            {cell.value} {cell.row.original.surname}
          </div>
          {cell.row.original.isTest ? (
            <ColorLabel color={LabelColors.PRIMARY} label="test" isSingleColor />
          ) : null}
        </>
      );
    },
    Header: "Name",
    accessor: "name",
    width: "auto",
    id: "name",
  };

  const phone: IColumn = {
    Cell: (cell) => (
      <div className="d-flex flex-column">
        <span
          onClick={() => handleCopy(cell.row.original.phone)}
          role="button"
          className="p-1 btn-link"
        >
          {cell.row.original.phone}
        </span>
        {cell.row.original.phoneCode ? (
          <ColorLabel
            className="fs-8"
            color={LabelColors.PRIMARY}
            label={cell.row.original.phoneCode}
            isSingleColor
          />
        ) : null}
      </div>
    ),
    Header: "Phone",
    accessor: "phone",
    width: "auto",
    id: "phone",
  };

  const email: IColumn = {
    Cell: (cell) => (
      <div className="d-flex flex-column">
        <span onClick={() => handleCopy(cell.value)} role="button" className="p-1 btn-link">
          {cell.value}
        </span>
      </div>
    ),
    Header: "Email",
    accessor: "email",
    width: "120px",
    id: "email",
  };

  const landing: IColumn = {
    Header: "Landing",
    accessor: "landing",
    width: "auto",
    id: "landing",
  };

  const location: IColumn = {
    Cell: ({ cell }) => (
      <div>{cell.value && cell.value?.location ? cell.value?.location : "-"}</div>
    ),
    Header: "Location",
    accessor: "localization",
    width: "auto",
    id: "location",
  };

  const source: IColumn = {
    Header: "Source",
    accessor: "source",
    width: "auto",
    id: "source",
  };

  const price: IColumn = {
    Header: "Price",
    accessor: "price",
    width: "auto",
    id: "price",
  };

  const ip: IColumn = {
    Header: "IP",
    accessor: "ip",
    width: "auto",
    id: "ip",
  };

  const referer: IColumn = {
    Header: "Referer",
    accessor: "referer",
    width: "auto",
    id: "referer",
  };

  const url: IColumn = {
    Header: "URL",
    accessor: "url",
    width: "auto",
    id: "url",
  };

  const clickId: IColumn = {
    Header: "Click Id",
    accessor: "clickId",
    width: "auto",
    id: "clickId",
  };

  const sub1: IColumn = {
    Header: "SUB 1",
    accessor: "sub1",
    width: "auto",
    id: "sub1",
  };

  const redirect: IColumn = {
    Header: "Redirect",
    accessor: "redirect",
    width: "auto",
    id: "redirect",
  };

  const sub2: IColumn = {
    Header: "SUB 2",
    accessor: "sub2",
    width: "auto",
    id: "sub2",
  };

  const sub3: IColumn = {
    Header: "SUB 3",
    accessor: "sub3",
    width: "auto",
    id: "sub3",
  };

  const sub4: IColumn = {
    Header: "SUB 4",
    accessor: "sub4",
    width: "auto",
    id: "sub4",
  };

  const sub5: IColumn = {
    Header: "SUB 5",
    accessor: "sub5",
    width: "auto",
    id: "sub5",
  };

  const client: IColumn = {
    Cell: (cell) => (
      <span>
        {cell.value ? (
          <div>
            <ColorLabel
              className="d-inline mr-2"
              colors={typeOptions}
              value={cell.value.type}
              label={cell.value.type}
            />
            <Link className="font-semibold" to={`/clients/${cell.value.id}`}>
              {cell.value.name}
            </Link>
          </div>
        ) : (
          "No"
        )}
      </span>
    ),
    Header: "Client",
    accessor: "client",
    width: "auto",
    id: "client",
  };

  const team: IColumn = {
    Cell: (cell) => (
      <span>
        {cell.value ? (
          <Link className="font-semibold" to={`/teams/${cell.value.id}`}>
            {cell.value.name}
          </Link>
        ) : (
          "No"
        )}
      </span>
    ),
    Header: "Team",
    accessor: "team",
    width: "auto",
    id: "team",
  };

  const affiliate: IColumn = {
    Cell: (cell) => {
      if (!cell.value && !cell.row.original.affiliate) return <span>No</span>;
      if (!cell.value || !superRolesWithPM.includes(role)) return <span>{cell.row.original.affiliate}</span>;
      return (
        <Link className="font-semibold" to={`/affiliates/${cell.value}`}>
          {cell.row.original.affiliate}
        </Link>
      );
    },
    Header: "Affiliate",
    accessor: "affiliateId",
    width: "auto",
    id: "affiliate",
  };

  const country: IColumn = {
    Cell: (cell) => (
      <div>
        {cell.value?.toLocaleUpperCase()}/{cell.row.original.language?.toLocaleUpperCase()}
      </div>
    ),
    Header: "Locale",
    accessor: "country",
    width: "50px",
    id: "locale",
  };

  const status: IColumn = {
    Cell: (cell) => {
      const [show, setShow] = useState(false);
      const [histories, setHistories] = useState<StatusHistory[]>([]);

      const fetchHistories = async () => {
        if (!cell.row.original.id) return;
        const data = await getLeadStatusesHistories(cell.row.original.id);
        setHistories(data.list);
        setShow(true);
      };

      return (
        <div>
          <div className="flex-center justify-between" onClick={fetchHistories}>
            <ColorLabel
              className="d-inline c-pointer"
              colors={statusOptions}
              value={cell.value}
              label={cell.value}
            />
          </div>
          <Modal show={show} onHide={() => setShow(false)} size="lg" centered>
            <Modal.Header closeButton>
              <Modal.Title>Statuses Histories</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div dangerouslySetInnerHTML={{ __html: joinProcessingHistories(histories) }} />
            </Modal.Body>
          </Modal>
        </div>
      );
    },
    Header: "Status",
    accessor: "status",
    width: "110px",
    id: "status",
  };

  const processing: IColumn = {
    Cell: (cell) => {
      const [show, setShow] = useState(false);
      const [histories, setHistories] = useState<StatusHistory[]>([]);

      const fetchHistories = async () => {
        if (!cell.row.original.id) return;
        const data = await getLeadProcessingHistories(cell.row.original.id);
        setHistories(data.list);
        setShow(true);
      };

      if (!cell.value) return null;

      return (
        <div>
          <div className="flex-center justify-between align-center" onClick={fetchHistories}>
            <ColorLabel
              className="d-inline c-pointer"
              colors={statusOptions}
              value={cell.value}
              label={cell.value}
            />
          </div>
          <Modal show={show} onHide={() => setShow(false)} size="lg" centered>
            <Modal.Header closeButton>
              <Modal.Title>Processing Histories</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div dangerouslySetInnerHTML={{ __html: joinProcessingHistories(histories) }} />
            </Modal.Body>
          </Modal>
        </div>
      );
    },
    Header: "Processing",
    accessor: "processingStatus",
    width: "auto",
    id: "processing",
  };

  const clientStatus: IColumn = {
    Cell: (cell) => {
      const [show, setShow] = useState(false);
      const [histories, setHistories] = useState<StatusHistory[]>([]);

      const fetchHistories = async () => {
        if (!cell.row.original.id) return;
        const data = await getLeadClientStatuses(cell.row.original.id);
        setHistories(data.list);
        setShow(true);
      };

      if (!cell.value) return null;

      return (
        <div>
          <div className="flex-center justify-between align-center" onClick={fetchHistories}>
            <ColorLabel
              className="d-inline c-pointer"
              colors={statusOptions}
              value={cell.value}
              label={cell.value}
            />
          </div>
          <Modal show={show} onHide={() => setShow(false)} size="lg" centered>
            <Modal.Header closeButton>
              <Modal.Title>Client statuses</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div dangerouslySetInnerHTML={{ __html: joinProcessingHistories(histories) }} />
            </Modal.Body>
          </Modal>
        </div>
      );
    },
    Header: "Client status",
    accessor: "clientStatus",
    width: "auto",
    id: "clientStatus",
  };

  const isValid: IColumn = {
    Cell: (cell) => (
      <CheckboxField
        value={Boolean(cell.value)}
        onChange={role === "admin" ? (value: boolean) => handleChangeIsValid(value, cell.row.original.id) : () => {}}
      />
    ),
    Header: "Valid",
    accessor: "isValid",
    width: "auto",
    id: "valid",
  };

  const isAutologin: IColumn = {
    Cell: (cell) => <Label status={Boolean(cell.value)} />,
    Header: "Autologin",
    accessor: "isAutologin",
    width: "auto",
    id: "isAutologin",
  };

  const isReplace: IColumn = {
    Cell: (cell) => (
      <CheckboxField
        value={Boolean(cell.value)}
        onChange={role === "admin" ? (value: boolean) => handleChangeIsReplace(value, cell.row.original.id) : () => {}}
      />
    ),
    Header: "Replace",
    accessor: "isReplace",
    width: "auto",
    id: "replace",
  };

  const depositedAt: IColumn = {
    Cell: (cell) => <span>{cell.value ? formatDateFullTime(cell.value) : "No"}</span>,
    Header: "Deposited at",
    accessor: "depositedAt",
    width: "auto",
    id: "depositedAt",
  };

  const sentAt: IColumn = {
    Cell: (cell) => <span>{cell.value ? formatDateFullTime(cell.value) : "No"}</span>,
    Header: "Sent at",
    accessor: "sentAt",
    width: "auto",
    id: "sentAt",
  };

  const createdAt: IColumn = {
    Cell: (cell) => <span>{formatDateFullTime(cell.value)}</span>,
    Header: "Created",
    accessor: "createdAt",
    width: "auto",
    id: "createdAt",
  };

  const columns = [
    numberIndex,
    name,
    phone,
    email,
    landing,
    source,
    affiliate,
    country,
    url,
    price,
    // ip,
    clickId,
    referer,
    location,
    // redirect,
    sub1,
    sub2,
    sub3,
    sub4,
    sub5,
    status,
    processing,
    clientStatus,
    isAutologin,
    isValid,
    isReplace,
    depositedAt,
    sentAt,
    createdAt,
  ];

  if (superRolesWithPM.includes(role)) {
    columns.splice(4, 0, client as FixMeLater);
    columns.splice(5, 0, team as FixMeLater);
  }

  if (role === UserRole.AFFILIATE_MANAGER) {
    columns.splice(4, 0, client as FixMeLater);
  }

  return columns;
}
